import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SliderCard1 from '../slides/slidercard1';
import SliderCard2 from '../slides/slidercard2';
import SliderCard3 from '../slides/slidercard3';
import SliderCard4 from '../slides/slidercard4';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import './slider.css';

import { Autoplay, Pagination, Navigation } from "swiper";

function Slider() {
    return (
        <>
      <Swiper className="carousel"
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false
        }}
        pagination={{
          clickable: true
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide className="slide"><SliderCard1 /></SwiperSlide>
        <SwiperSlide className="slide"><SliderCard2 /></SwiperSlide>
        <SwiperSlide className="slide"><SliderCard3 /></SwiperSlide>
        <SwiperSlide className="slide"><SliderCard4 /></SwiperSlide>
      </Swiper>
    </>
    )
}

export default Slider
