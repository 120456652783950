
import React from 'react';
import './slidecard3.css';


function Slidercard3() {
    return (
        <div>
            <div className="text-1">
                <h1>OG Dogeboy NFT</h1>
            </div>
            <div className="slider3-container">
                <div className="og-head"><img src={require('../../../assets/OG-head.png')} ></img></div>
                <div className="hero-text">
                    <p>Mint Date: <b>Coming Soon</b></p>
                </div>
            </div>
        </div>
        
    )
}

export default Slidercard3
