import React from 'react';
import About from './about/about';
import Roadmap from './roadmap/roadmap';
import Slider from './slider/slider';
import Team from './team/team';
import Traits from './traits/traits';
import './body.css';

function body() {
    return (
        <div className="body">
            <section id="slider">
                <Slider />
            </section>
            <section id="about">
                <About />
            </section>
            <section id="traits">
                <Traits />
            </section>
            <section id="roadmap">
                <Roadmap />
            </section>
            <section id="team">
                <Team />
            </section>
        </div>
    )
}

export default body
