import React from 'react';

function Slidercard2() {
    return (
        <div className="side2-container">
            <div className="text-1">
                <h1>Streetwear</h1> 
            </div>
            <div><img src={require('../../../assets/chillsbury-shirt.png')} className="social-icons"></img></div>
            <div>
                <li>Durable</li>
                <li>Comfortable</li>
                <li>Chill AF</li>
            </div>
        </div>
    )
}

export default Slidercard2
