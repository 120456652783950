import React from 'react';
import './about.css';



function About() {
    return (
           <div className='about-section'>
               
                <div className='about-info'>
                    <h1> ABOUT</h1>
                        <p>ARE WE REALLY "ALL ABOUT THE DOUGH"? OF COURSE ! <br/> WHY ? IT'S REALLY 
                        QUITE SIMPLE. EVERYBODY KNEADS IT! <br/>
                        THE CHILLSBURY DOGEBOY BRAND AT IT'S CORE REPRESENTS 
                        INTERSECTION OF STREET SMARTS, TECHNOLOGY AND 
                        FINANCE. <br/> MAKING THAT DOUGH, 
                        DOING ONLY GOOD EVERYDAY, AND BEING THE CHILLEST 
                        PEOPLE ON THE PLANET ARE THE CORE VALUES OF OUR COMMUNITY AND THE COMPANY 
                        WE KEEP.
                        </p> 
                        <div className='line'>
                        </div>

               </div>
               <div className='about-logo'>
                    <img src={require('../../../assets/DOGEBOY_IMAGE_ABOUT_SECTION-copy.png')} className="picture"></img>
               </div>
                </div>
    )}


export default About
