import React from 'react';
import './roadmap.css';

function Roadmap() {
    return (
        <div>
            <h1>Chillsbury <span>Streetmap</span></h1>

<div class="container">

   <div class="timeline-block timeline-block-right">
      <div class="marker"></div>
      <div class="timeline-content">
         <h3>Create Twitter</h3>
         <p>Create twitter account & register with Bonfida</p>
      </div>
   </div>

   <div class="timeline-block timeline-block-left">
      <div class="marker"></div>
      <div class="timeline-content">
         <h3>NFT Artwork</h3>
         <p>Finalize the OG Dogeboy NFT artwork</p>
      </div>
   </div>

   <div class="timeline-block timeline-block-right">
      <div class="marker"></div>
      <div class="timeline-content">
         <h3>Discord</h3>
         <p>Invite Friends & Family to join the Discord, In order to create a pawsitive community around the Chillsbury Brand</p>
      </div>
   </div>

   <div class="timeline-block timeline-block-left">
      <div class="marker"></div>
      <div class="timeline-content">
         <h3>Exclusive Giveaways</h3>
         <p>Giveaway NFTs to OG Discord Members at 1069, 4069, & 6069 total Members</p>
      </div>
   </div>

   <div class="timeline-block timeline-block-right">
      <div class="marker"></div>
      <div class="timeline-content">
         <h3>Non-Profit Partner</h3>
         <p>We are Team No Kid Hungry. We will donate 6.9% of the OG DOGEBOY NFT Mint to our Preferred Non-Profit, No Kid Hungry</p>
      </div>
   </div>

   <div class="timeline-block timeline-block-left">
      <div class="marker"></div>
      <div class="timeline-content">
         <h3>Launch Mint of OG Dogeboy</h3>
         <p>Mint Date: <b>Coming Soon</b></p>
      </div>
   </div>

   <div class="timeline-block timeline-block-right">
      <div class="marker"></div>
      <div class="timeline-content">
         <h3>Mint Out</h3>
         <p>Mint all 6,069 OG Dogeboy NFTs</p>
      </div>
   </div>

   <div class="timeline-block timeline-block-left">
      <div class="marker"></div>
      <div class="timeline-content">
         <h3>Verify "Doughp House" Members</h3>
         <p>Open registration for Doughp House membership</p>
      </div>
   </div>

   <div class="timeline-block timeline-block-right">
      <div class="marker"></div>
      <div class="timeline-content">
         <h3>OG Dogeboy Drip Drop</h3>
         <p>Drop claim site for Chillsbury Dogeboy Drip</p>
      </div>
   </div>

   <div class="timeline-block timeline-block-left">
      <div class="marker"></div>
      <div class="timeline-content">
         <h3>Purchase Portals</h3>
         <p>Chillsbury Dogeboy Enters the Solana Metaverse with the purchase of a PORTals NFT for the community</p>
      </div>
   </div>

   <div class="timeline-block timeline-block-right">
      <div class="marker"></div>
      <div class="timeline-content">
         <h3>Launch 3D Dogeboy NFT</h3>
         <p>Create and Launch 3D Dogeboy</p>
      </div>
   </div>

   <div class="timeline-block timeline-block-left">
      <div class="marker"></div>
      <div class="timeline-content">
         <h3>Launch Utility Token</h3>
         <p>Snapshot & Token airdrop for OG Dogeboy NFT & 3D Dogeboy NFT holders</p>
      </div>
   </div>
</div>
        </div>
    )
}

export default Roadmap
