import React from 'react';
import './team.css';



function Team() {
    return (
        <div className="team-section">
            <div className="team-header">
                <h1>Meet The Team</h1>
            </div>
                <div className="tm-container">
                    <div className="team-member">
                        <img src={require('../../../assets/donp.png')} alt="" className="profile"></img>
                        <div className="name">Full Stack P</div>
                        <div className="title">Developer</div>
                        <div className="about">"The Joker"</div>
                        <div className="social-links"> 
                            <a href="https://twitter.com/fullstack_pq" target="_blank"><img src={require('../../../assets/twitter.png')} className="social-icons"></img></a>
                            <a href="https://github.com/PeterdQuinn" target="_blank"><img src={require('../../../assets/github.png')} className="social-icons"></img></a>
                            <a href="https://www.instagram.com/soy_loreal_full_stack_p/" target="_blank"><img src={require('../../../assets/instagram.png')} className="social-icons"></img></a>
                            <a href="https://www.linkedin.com/in/peter-quinn-73ab78200/" target="_blank"><img src={require('../../../assets/linkedin.png')} className="social-icons"></img></a>
                        </div>
                    </div>
                    <div className="team-member">
                        <img src={require('../../../assets/macice.png')} alt="" className="profile"></img>
                        <div className="name">Mac Ice</div>
                        <div className="title">Developer</div>
                        <div className="about">"The Truth"</div>
                        <div className="social-links"> 
                            <a href="https://twitter.com/malcolmidlebird" target="_blank"><img src={require('../../../assets/twitter.png')} className="social-icons"></img></a>
                            <a href="https://github.com/jidlebir" target="_blank"><img src={require('../../../assets/github.png')} className="social-icons"></img></a>
                            <a href="https://www.instagram.com/malcolmbyanymeans/" target="_blank"><img src={require('../../../assets/instagram.png')} className="social-icons"></img></a>
                            <a href="https://www.linkedin.com/in/malcolmidlebird/" target="_blank"><img src={require('../../../assets/linkedin.png')} className="social-icons"></img></a>
                        </div>
                    </div>
                    <div className="team-member">
                        <img src={require('../../../assets/chills.png')} alt="" className="profile"></img>
                        <div className="name">Chills_</div>
                        <div className="title">Founder</div>
                        <div className="about">"The Answer"</div>
                        <div className="social-links"> 
                            <a href="https://twitter.com/Chills_Dogeboy" target="_blank"><img src={require('../../../assets/twitter.png')} className="social-icons"></img></a>
                            <a href="https://github.com/ChillsburyDogeboy" target="_blank"><img src={require('../../../assets/github.png')} className="social-icons"></img></a>
                            <a href="https://www.instagram.com/chillsburydogeboy/" target="_blank"><img src={require('../../../assets/instagram.png')} className="social-icons"></img></a>
                            
                        </div>
                    </div>
                </div>    
        </div>
    )
}

export default Team
