import React from 'react';
import './slidecard4.css';

function Slidercard4() {
    return (
        <div className="no-kid-slide">
            <div className="no-kid-container">
                <div className="images">
                    <div className="no-kid-hungry">
                        <img src={require('../../../assets/NoKidHungry.png')} ></img>
                    </div>
                    <div className="collaboration">
                    <img src={require('../../../assets/x.png')} ></img>
                    </div>
                    <div className="mascot-slide4">
                        <img src={require('../../../assets/Dogboy-Mascot.png')} ></img>
                    </div>
                </div>
            </div>
            <div className="pledge"><p>6.9% of the OG Dogeboy NFT Mint will be Donated to No Kid Hungry</p></div>
        </div>
    )
}

export default Slidercard4
