import React from 'react';
import './footer.css';

function Footer() {
    return (
        <div className="footer-section">
            <div className="company-links">
                <a href="https://discord.com/invite/8PmnbvCh23" target="_blank"><img src={require('../../assets/discord.png')} className="business-social-icons"></img></a>
                <a href="https://twitter.com/OGDogeboyNFT" target="_blank"><img src={require('../../assets/twitter.png')} className="business-social-icons"></img></a>
            </div>
            <div>
            <p className="copyright">Copyright ©2022 Chillbury Dogeboy. All Rights Reserved</p>
            </div>
            
        </div>
    )
}

export default Footer
