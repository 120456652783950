import React from 'react';
import './slidecard1.css';

function SliderCard1() {
    return (
        <div className="slide-1">
            <div className="side1-container">
                <div className="text-1"><h1>Welcome to Chillsbury</h1></div> 
                <div className="mascot"><img src={require('../../../assets/Dogboy-Mascot.png')} className="slide-mascot"></img></div>
                <div className="text-2"><h1>Dogeboy</h1></div>
                <div className="text-3"><p>Drip, Drops, and NFTs</p></div>
            </div>
        </div>

    )
}

export default SliderCard1;
