import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

import './traits.css';

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper";

function Traits(  ) {
  
    return (
        <>
        <div className="trait-header">
            <h1>Traits</h1>
        </div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={false}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/SKINS/0.png')} />
          <div className="trait-details">
            <h4>Skins</h4>
            <h5>Original</h5>
            
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/SKINS/1.png')} />
          <div className="trait-details">
            <h4>Skins</h4>
            <h5>Purple</h5>
            
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/SKINS/2.png')} />
          <div className="trait-details">
            <h4>Skins</h4>
            <h5>Chocolate</h5>
          
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/SKINS/3.png')} />
          <div className="trait-details">
            <h4>Skins</h4>
            <h5>Zombie</h5>
           
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/SKINS/4.png')} />
          <div className="trait-details">
            <h4>Skins</h4>
            <h5>Skelly</h5>
         
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/SKINS/5.png')} />
          <div className="trait-details">
            <h4>Skins</h4>
            <h5>Pink</h5>
            
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/SKINS/6.png')} />
          <div className="trait-details">
            <h4>Skins</h4>
            <h5>Red</h5>
           
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/SKINS/7.png')} />
          <div className="trait-details">
            <h4>Skins</h4>
            <h5>Solana</h5>
            
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/SKINS/8.png')} />
          <div className="trait-details">
            <h4>Skins</h4>
            <h5>Blue</h5>
   
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/0.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Naked</h5>
        
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/1.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Poncho</h5>
           
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/2.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Black Apron</h5>
         
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/3.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Purple Apron</h5>
    
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/4.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Green Apron</h5>
      
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/5.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Pink Tuxedo</h5>
         
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/6.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Light Grey Hoodie</h5>
          
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/7.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Reverend</h5>
           
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/8.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Pirate Jacket</h5>
           
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/9.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Name: Tuxedo</h5>
          
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/10.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Black Hoodie</h5>
           
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/11.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Camouflage Jacket</h5>
            
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/12.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Miami Vice Apron</h5>
          
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/13.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Biker Jacket</h5>
         
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/14.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Phoenix Windbreaker</h5>
         
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/15.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>New York Windbreaker</h5>
        
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/16.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Blue Hoodie</h5>
           
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/17.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Chicago Windbreaker</h5>
            
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/18.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Grey Apron</h5>
         
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/CLOTHES/19.png')} />
          <div className="trait-details">
            <h4>Clothes</h4>
            <h5>Blue Apron</h5>
        
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/EYES/0.png')} />
          <div className="trait-details">
            <h4>Eyes</h4>
            <h5>VR Headset</h5>

          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/EYES/1.png')} />
          <div className="trait-details">
            <h4>Eyes</h4>
            <h5>Bandana Rafael</h5>
           
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/EYES/2.png')} />
          <div className="trait-details">
            <h4>Eyes</h4>
            <h5>Exchange Shades</h5>
       
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/EYES/3.png')} />
          <div className="trait-details">
            <h4>Eyes</h4>
            <h5>Doggers</h5>
         
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/EYES/4.png')} />
          <div className="trait-details">
            <h4>Eyes</h4>
            <h5>Solana Shades</h5>
       
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/EYES/5.png')} />
          <div className="trait-details">
            <h4>Eyes</h4>
            <h5>Laser Eyes</h5>
        
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/EYES/6.png')} />
          <div className="trait-details">
            <h4>Eyes</h4>
            <h5>Bandana Michel Angaelo</h5>
         
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/EYES/7.png')} />
          <div className="trait-details">
            <h4>Eyes</h4>
            <h5>Bandana Leonardo</h5>
        
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/EYES/8.png')} />
          <div className="trait-details">
            <h4>Eyes</h4>
            <h5>None</h5>
          
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/EYES/9.png')} />
          <div className="trait-details">
            <h4>Eyes</h4>
            <h5>Neo Shades</h5>
           
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/EYES/10.png')} />
          <div className="trait-details">
            <h4>Eyes</h4>
            <h5>Dough Eyes</h5>
 
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/EYES/11.png')} />
          <div className="trait-details">
            <h4>Eyes</h4>
            <h5>Bandana Donatello</h5>
    
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/HEAD/0.png')} />
          <div className="trait-details">
            <h4>Head</h4>
            <h5>Black Chef Hat</h5>
       
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/HEAD/2.png')} />
          <div className="trait-details">
            <h4>Head</h4>
            <h5>Goku</h5>
     
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/HEAD/3.png')} />
          <div className="trait-details">
            <h4>Head</h4>
            <h5>Red Headphones</h5>
     
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/HEAD/4.png')} />
          <div className="trait-details">
            <h4>Head</h4>
            <h5>Pirate Hat</h5>
      
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/HEAD/5.png')} />
          <div className="trait-details">
            <h4>Head</h4>
            <h5>Camouflage Chef Hat</h5>
        
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/HEAD/6.png')} />
          <div className="trait-details">
            <h4>Head</h4>
            <h5>Blue Headphones</h5>
         
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/HEAD/7.png')} />
          <div className="trait-details">
            <h4>Head</h4>
            <h5>Blue Flower</h5>
       
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/HEAD/8.png')} />
          <div className="trait-details">
            <h4>Head</h4>
            <h5>Bubble Gum Brain</h5>
      
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/HEAD/9.png')} />
          <div className="trait-details">
            <h4>Head</h4>
            <h5>Super Saiyan</h5>
        
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/HEAD/10.png')} />
          <div className="trait-details">
            <h4>Head</h4>
            <h5>None</h5>
        
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/HEAD/11.png')} />
          <div className="trait-details">
            <h4>Head</h4>
            <h5>Pink Flower</h5>
        
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/HEAD/12.png')} />
          <div className="trait-details">
            <h4>Head</h4>
            <h5>Miami Vice Hat</h5>
         
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/ITEM/0.png')} />
          <div className="trait-details">
            <h4>Item</h4>
            <h5>Poke Ball</h5>
     
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/ITEM/2.png')} />
          <div className="trait-details">
            <h4>Item</h4>
            <h5>Bone</h5>
 
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/ITEM/3.png')} />
          <div className="trait-details">
            <h4>Item</h4>
            <h5>Bubble Gum</h5>
       
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/ITEM/4.png')} />
          <div className="trait-details">
            <h4>Item</h4>
            <h5>None</h5>
       
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/ITEM/5.png')} />
          <div className="trait-details">
            <h4>Item</h4>
            <h5>Bee Hive</h5>
    
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/ITEM/6.png')} />
          <div className="trait-details">
            <h4>Item</h4>
            <h5>Blunt</h5>
       
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/ITEM/7.png')} />
          <div className="trait-details">
            <h4>Item</h4>
            <h5>Blueberry</h5>
      
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/ITEM/8.png')} />
          <div className="trait-details">
            <h4>Item</h4>
            <h5>Vape</h5>
          
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/ITEM/9.png')} />
          <div className="trait-details">
            <h4>Item</h4>
            <h5>Grenade</h5>
       
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/ITEM/10.png')} />
          <div className="trait-details">
            <h4>Item</h4>
            <h5>Exchange Logo</h5>
          
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/NECK/0.png')} />
          <div className="trait-details">
            <h4>Neck</h4>
            <h5>Ruby Collar</h5>
          
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/NECK/2.png')} />
          <div className="trait-details">
            <h4>Neck</h4>
            <h5>Saphire Collar</h5>
        
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/NECK/3.png')} />
          <div className="trait-details">
            <h4>Neck</h4>
            <h5>Gold Chain</h5>
      
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/NECK/4.png')} />
          <div className="trait-details">
            <h4>Neck</h4>
            <h5>None</h5>
       
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/NECK/5.png')} />
          <div className="trait-details">
            <h4>Neck</h4>
            <h5>Spiked Collar</h5>
           
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/BACKGROUND/0.png')} />
          <div className="trait-details">
            <h4>Background</h4>
            <h5>Green</h5>
           
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/BACKGROUND/1.png')} />
          <div className="trait-details">
            <h4>Background</h4>
            <h5>Yellow</h5>
            
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/BACKGROUND/2.png')} />
          <div className="trait-details">
            <h4>Background</h4>
            <h5>Blue</h5>
           
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/BACKGROUND/3.png')} />
          <div className="trait-details">
            <h4>Background</h4>
            <h5>Orange</h5>
            
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/BACKGROUND/4.png')} />
          <div className="trait-details">
            <h4>Background</h4>
            <h5>Red</h5>
           
          </div>
        </SwiperSlide>
        <SwiperSlide className="carousel">
          <img src={require('../../../assets/A/BACKGROUND/5.png')} />
          <div className="trait-details">
            <h4>Background</h4>
            <h5>Purple</h5>
            
          </div>
        </SwiperSlide>
        
        
      
        
      </Swiper>
      
    </>
    )
}

export default Traits