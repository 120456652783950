import React from 'react';
import './header.css';




function Header() {
   
    return (
        <div className="header">
            <div className="logo"><img src={require('../../assets/Dogboy-Mascot.png')} className="logo-picture"></img></div>
            <div className="menu">
                
                    <div className="menu-item">
                        <a href="#about">
                            About
                        </a>
                    </div>
                    <div className="menu-item">
                        <a href="#traits">
                            Traits
                        </a>
                        
                    </div>
                    <div className="menu-item">
                        <a href="#roadmap">
                            Streetmap
                        </a>
                        
                    </div>
                    <div className="menu-item">
                        <a href="#team">
                            Team
                        </a>
                    </div>
            </div>
            <div className="social-links">
                <a href="https://discord.com/invite/8PmnbvCh23" target="_blank"><img src={require('../../assets/discord.png')} className="header-social-icons"></img></a>
                <a href="https://twitter.com/OGDogeboyNFT" target="_blank"><img src={require('../../assets/twitter.png')} className="header-social-icons"></img></a>
            </div>
        </div>
    )
}

export default Header
